










import { Vue, Component, Prop } from "vue-property-decorator";
//import Chat from "@/common-app/components/Chat.vue";
import Alert from "@/common-app/components/Alert.vue";
@Component({
  components: {
    //Chat,
    Alert
  }
})
export default class App extends Vue {}
